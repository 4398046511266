<template>
  <div class="defrayBox">
    <div @click="goBackBtn" class="userInfo">
      <div class="flex">
        <img
          class="defrayImg"
          src="../../assets/images/succeed-img.png"
          alt=""
        />
        <!-- <div>
          <div class="color-red">订单完成!</div>
          <div class="color-hui">再来一笔吧!</div>
        </div> -->
      </div>
      <!-- <div>订单金额: {{ OrderCost }}</div>
      <div>客户名: {{ SendOrgName }}</div>
      <div>订单编号: {{ orderNo }}</div> -->
      <button class="goBack" @click.stop="goBackBtn()">返回首页</button>
    </div>
    <!-- <div v-if="!isShow" class="noInfo" @click="getOrderInfo">
      <img src="../../assets/images/fail.png" alt="" />
      网络卡了,点击重新获取
      <button class="goBack" @click.stop="goBackBtn">返回首页</button>
    </div> -->
  </div>
</template>


<script>
export default {
  data() {
    return {
      BatchNo: "",
      orderNo: "", //订单编号
      SendOrgName: "",
      OrderCost: "",
     
    };
  },
  created() {
    var that = this;
    // if (location.search) {
    //   const datas = that.public.urlObj(location.href);
    //   that.BatchNo = datas.orderNo;
    // }
    // that.getOrderInfo();
    localStorage.setItem('orgId',that.public.getConfig.defaultOrgId)
  },
  methods: {
    goBackBtn() {
      var href = "/";
      // let mchData ={action:'jumpOut', jumpOutUrl:'https://app.yaomengzhu.com'};
      let mchData ={action:'jumpOut', jumpOutUrl:href};
    	let postData = JSON.stringify(mchData);
    	parent.postMessage(postData,'https://payapp.weixin.qq.com');
    //  top.location.href ="https://app.yaomengzhu.com";
    },
    getOrderInfo() {
      var that = this;
      let data = {
        BatchNo: that.BatchNo,
      };
      that.public.request(
        "post",
        "/api/OrderPay/GetOrderInfoByNo",
        data,
        (res) => {
          if (res.data.errorCode == "00") {
            that.isShow = true;
            that.orderNo = res.data.rows.order.OrderNo;
            that.SendOrgName = res.data.rows.order.SendOrgName;
            that.OrderCost = res.data.rows.order.OrderCost;
            that.OrderNoByLogin();
          } else {
            // that.goBackBtn();
          }
        }
      );
    },
    // 根据订单号登陆
    OrderNoByLogin() {
      var that = this;
      let data = {
        ReceiveOrgId: that.public.getConfig.defaultOrgId,
        OrderNo: that.orderNo,
      };
      that.public.request(
        "post",
        "/api/AppLogin/OrderNoByLogin",
        data,
        (res) => {
          if (res.data.errorCode == "00") {
            sessionStorage.setItem("member_token", res.data.rows);
            localStorage.setItem("orgId", res.data.extend.currentOrgId);
            sessionStorage.setItem("customer", res.data.extend.customerId);
            var webconfig = JSON.stringify(res.data.extend.webConfig);
            localStorage.setItem("webconfig", webconfig);
          } else {
            //  that.goBackBtn();
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.defrayBox {
  margin-bottom: 0.4rem;
  font-size: 0.3rem;
  color: rgb(97, 97, 97);
}
.color-hui {
  color: rgb(143, 143, 143);
  font-size: 0.32rem;
}
.color-red {
  color: rgb(168, 13, 13);
  font-size: 0.45rem;
  margin: 0.15rem 0;
}
.userInfo {
  margin-left: 0.6rem;
}
.goBack {
  padding: 0.2rem 2.5rem;
  background-color: rgb(254, 78, 24);
  border-radius: 0.5rem;
  color: white;
  margin-top: 0.4rem;
}
.money {
  text-align: center;
}
/* .flex {
  display: flex;
  align-items: center;
  margin-left: 0.7rem;

} */
.defrayImg {
  width: 30%;
  padding: 0.5rem 0 0.2rem 0;
  margin: 0 auto;
}
.noInfo {
  margin: 1rem 1.5rem;
  text-align: center;
}
.noInfo img {
  width: 60%;
  margin: 0.2rem auto;
}
</style>